/* You can add global styles to this file, and also import other style files */
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";

@import './variables';

.ag-theme-material {
    @include ag-theme-material((
        background-color: $bg-light-color,
        header-background-color: $bg-light-color,
        border-color: rgba(0,0,0,.5),
        grid-size: 8px,
        row-hover-color: darken($bg-light-color, 5%)
        ))
};