

// Colors

$bg-light-color: #F6F7F9;
$primary-color: #00539E;
$warning-color: #FE8B21;
$header-color: #FFFFFF;

$label-normal:#5F6369;
$label-active: $primary-color;
$label-error: #E25353;

$scrollbar-color: #aaa;
$formpage-label-color: #333333;


// sizes

$label-text-size: 12px;
$help-text-size:12px;
$input-text-size: 14px;
