// Custom Theming for Angular Material
@use '@angular/material' as mat;
@import './assets/sass/custom-app-theme.scss';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$common-user-management-ui-primary: mat.define-palette($mat-app-dark-blue, 900);
$common-user-management-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$common-user-management-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$common-user-management-ui-theme: mat.define-light-theme((
  color: (
    primary: $common-user-management-ui-primary,
    accent: $common-user-management-ui-accent,
    warn: $common-user-management-ui-warn,
  )
));

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: 'Montserrat, sans-serif',
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(16px, 24px, 500)
);

.mat-typography {
  background: #F6F7F9;
}

// include mat typography
@include mat.core($custom-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($common-user-management-ui-theme);

/* You can add global styles to this file, and also import other style files */

/** ag grid scss */
@import './assets/sass/ag-grid-overrides';

$theme-colors: (
  "primary": $primary-color,
);

@import '~bootstrap/scss/bootstrap.scss';
@import './assets/sass/core';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: $bg-light-color;
}

mat-label {
  color: $formpage-label-color;
}

.mat-form-field-should-float {
  mat-label {
    font-weight: bold;
  }
}


.mat-mini-fab.small {
  height: 32px;
  width: 32px;

  .mat-button-wrapper {
    padding: 4px 0 !important;
    line-height: 24px;

    mat-icon {
      font-size: 18px;
    }
  }
}

.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  &.small {
    line-height: 32px;

    .mat-icon {
      height: 26px;
    }
  }
}

.custom-paginator-container {
  justify-content: flex-end;

  .custom-paginator-counter {
    white-space: nowrap;
    margin: 0 3rem 0 10px !important;
  }

  .mat-paginator-navigation-first, .mat-paginator-navigation-last {
    margin: 0 1rem;
    border-radius: 0;
    outline: none;

    .mat-paginator-icon {
      display: none;
    }

    .custom-text {
      font-size: 12px;
      font-weight: bold;
      color: $primary-color;
    }

    &.custom-paginator-arrow-disabled {
      .custom-text {
        color: #0000008a;
      }
    }
  }

  .custom-paginator-page {
    border-radius: 50%;
    outline: none;
    border: none;
    margin: 0.4em;
    font-weight: bold;
    font-size: 12px;
    min-width: 24px;
    width: auto;
    min-height: 24px;
    max-height: 24px;
    padding: 1px 6px;
  }

  .custom-paginator-page-enabled {
    color: $primary-color;
    background: transparent;
    cursor: pointer;

    &:hover {
      background-color: #dfe3e6;
    }
  }

  .custom-paginator-page-disabled {
    background-color: $primary-color;
    color: #fff;
  }

}

.custom-table {
  &.loading {
    background: whitesmoke;
    pointer-events: none;
    user-select: none;
  }

  .mat-header-cell {
    color: #333333;
    font-weight: 600;

    .mat-sort-header-arrow {
      font-weight: bold;
    }
  }

  .mat-cell {
    font-weight: 400;
    font-size: 14px;
  }

  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    border-bottom-style: dotted !important;
    border-width: 2px;
  }
}

.page-container {

  .page-title {
    font-size: 36px;
    font-weight: 300;
    text-transform: capitalize;
    font-style: normal;
  }

  .card-title {
    color: #333333;
    font-weight: 500;
    font-size: 24px;
  }

  .loader {
    z-index: 1;
    position: relative;
    top: 80px;
  }

  .card-tabs {
    &.hide-tabs {
      .mat-tab-header {
        display: none;
      }
    }

    .mat-tab-header {
      background-color: white;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
      border-radius: 4px;

      .mat-tab-label-active .mat-tab-label-content {
        font-weight: bold;
        color: black;
      }
    }
  }

  .mat-card:not([class*=mat-elevation-z]) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  }
}

.text-pre {
  white-space: pre-wrap;
}

form.read-only {

  input[readonly]::placeholder {
    color: transparent;
  }

  .mat-form-field-type-mat-select.mat-form-field-appearance-legacy {
    &.mat-form-field-disabled {
      .mat-select-value {
        color: rgba(0, 0, 0, 0.87);
      }

      .mat-select-arrow {
        border: none;
      }
    }
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-empty.mat-form-field-label {
    &:after {
      content: '-';
    }

    .ng-star-inserted {
      display: none;
    }
  }
}

.progress-amber {
  .mat-progress-bar-fill::after {
    background-color: #fe8b21 !important;
  }
  .mat-progress-bar-buffer {
      background-color: #d8d8d8;
  }
}

.progress-green {
  .mat-progress-bar-fill::after {
    background-color: #04bc28 !important;
  }
  .mat-progress-bar-buffer {
    background-color: #d8d8d8;
}
}

.progress-red {
  .mat-progress-bar-fill::after {
    background-color: #ff0000 !important;
  }
  .mat-progress-bar-buffer {
    background-color: #d8d8d8;
}
}

